<template>
  <index-view
    view-name="sim_providers"
    :module="module"
    :model="model"
    :list="list"
    :pagination="pagination"
    :sort="sort"
    :view-config="viewConfig"
    :fetch="fetch"
  />
</template>

<script>
import indexView from '@/global/components/Index'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('base/sim-providers')
export default {
  name: 'SimProvidersView',
  components: {
    'index-view': indexView
  },
  props: {
    module: {
      type: String,
      default: 'base'
    },
    model: {
      type: String,
      default: 'sim-providers'
    }
  },
  computed: {
    ...mapGetters(['list', 'pagination', 'sort', 'viewConfig'])
  },
  methods: {
    ...mapActions(['fetch'])
  }
}
</script>
