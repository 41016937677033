<template>
  <table-index
    :config="tableConfig"
    :create-config="createDetailsConfig"
    @tab-content-instance="instance => tabContentInstance = instance"
    @main-table-instance="instance => tableInstance = instance"
  />
</template>

<script>
import TableIndex from '@/global/components/table/TableIndex.vue'
import { api } from '@/global/services/api'

export default {
  name: 'OrganizationalUnitView',

  components: {
    TableIndex
  },

  data () {
    return {
      tabContentInstance: null,
      tableInstance: null
    }
  },

  computed: {
    tableConfig () {
      return {
        tableConfig: {
          apiConfig: {
            get: {
              module: 'fleet',
              route: 'vehicle-organizational-unit',
              method: 'get'
            }
          },
          rowClick: (event) => this.showOrganizationalUnitDetails(event),
          search_and_buttons_config: {
            newClick: (event) => this.showCreateNewOrganizationalUnit(event)
          },
          filters_and_columns_visibility_config: {
            showRefreshButton: false,
            showFiltersButton: false,
            showColumnsVisibilityButton: false
          },
          title_and_back_config: {
            title: this.$t('fleet.organizational_unit')
          },
          tableConfig: {
            styles: {
              useFixedHeaderWidth: true,
              headersHeight: '76px',
              dataRowHeight: '64px'
            },
            headers: [
              {
                label: this.$t('fleet/documents/prl_cargo.organizational_unit_number'),
                key: 'id',
                style: {
                  width: '100px'
                },
                always_visible: true
              },
              {
                label: this.$t('fleet/vehicle_document.name'),
                key: 'name',
                style: {
                  width: '200px'
                },
                always_visible: true
              }
            ],
            actionsConfig: [
              {
                label: this.$t('base.edit'),
                icon: 'mdi-pencil-box',
                click: (event) => this.showOrganizationalUnitDetails(event)
              },
              {
                label: this.$t('base.delete'),
                icon: 'mdi-delete',
                click: (event) => this.onOrganizationalUnitDelete(event)
              }
            ]
          }
        },
        breadcrumbs: {
          items: [
            {
              text: this.$t('fleet.travel_documents')
            },
            {
              text: this.$t('fleet.settings')
            },
            {
              text: this.$t('fleet.organizational_unit'),
              bolded: true
            }
          ]
        }
      }
    },

    createDetailsConfig () {
      return {
        tabs: [
          {
            title: this.$t('fleet/vehicle.basic_info'),
            apiConfig: {
              post: {
                module: 'fleet',
                route: 'vehicle-organizational-unit',
                method: 'post'
              }
            },
            afterSave: (event) => this.redirectToTable(event),
            tabContent: [
              {
                title: this.$t('fleet/vehicle.basic_info'),
                type: 'columns',
                showButtons: true,
                fullWidth: true,
                rows: [
                  {
                    type: 'fields',
                    fields: [
                      {
                        key: 'name',
                        label: this.$t('fleet/vehicle_document.name'),
                        creatable: true,
                        editable: true,
                        visible: true
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ],
        breadcrumbs: {
          items: [
            {
              text: this.$t('fleet.travel_documents')
            },
            {
              text: this.$t('fleet.prl_cargo')
            },
            {
              text: this.$t('fleet/documents/prl_cargo.creating_organizational_unit'),
              bolded: true
            }
          ]
        }
      }
    },

    detailsConfig () {
      return {
        title: this.$t('fleet/vehicle.basic_info'),
        apiConfig: {
          post: {
            module: 'fleet',
            route: (data, isCreate) => {
              const { id } = data || {}
              return id ? `vehicle-organizational-unit/${id}` : ''
            },
            method: 'post'
          }
        },
        tabContent: [
          {
            title: this.$t('fleet/vehicle.basic_info'),
            type: 'columns',
            showButtons: true,
            fullWidth: true,
            rows: [
              {
                type: 'fields',
                fields: [
                  {
                    key: 'name',
                    label: this.$t('fleet/vehicle_document.name'),
                    creatable: true,
                    editable: true,
                    visible: true
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  },

  methods: {
    showOrganizationalUnitDetails (event) {
      const { index, instance } = event

      if (instance && instance.showTableDetailsPage && typeof instance.showTableDetailsPage === 'function') {
        instance.showTableDetailsPage(index, this.detailsConfig)
      }
    },

    redirectToTable () {
      const { cancelTabSave } = this.tabContentInstance || {}

      if (cancelTabSave && typeof cancelTabSave === 'function') {
        cancelTabSave()
      }
    },

    showCreateNewOrganizationalUnit (event) {
      if (event && event.showTableNewPage && typeof event.showTableNewPage === 'function') {
        event.showTableNewPage()
      }
    },

    async onOrganizationalUnitDelete (event) {
      try {
        const { id } = event?.data || {}
        const { fetchData } = this.tableInstance || {}
        if (id) {
          await api().fleet.delete(`vehicle-organizational-unit/${id}`)

          if (fetchData && typeof fetchData === 'function') {
            fetchData()
          }
        }
      }
      catch (exception) {
        console.log(exception)
      }
    }
  }
}
</script>
