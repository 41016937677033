<template>
  <div
    :style="wrapperStyles"
  >
    <v-container fluid>
      <v-card :disabled="loader"  class="pa-0">
        <v-card-text class="pa-0">
          <v-data-table
            v-show="true"
            v-stripped
            :height="'71vh'"
            class="elevation-1"
            :headers="header"
            item-key="id"
            :loading="loader"
            :items="list"
            :options.sync="options"
            :server-items-length="pagination.total"
            :items-per-page="pagination.perPage"
            :footer-props="{ itemsPerPageOptions: [20, 50, 100] }"
            :sort-by.sync="options.sortBy"
            :sort-desc.sync="options.sortDesc"
            @update:options="getItems"
          >
            <template #top>
              <v-toolbar
                flat
              >
                <v-toolbar-title class="toolbar-title" v-text="$t('base.users_activity')" />
                <v-col>
                  <v-btn
                    icon
                    @click="getItems()"
                  >
                    <v-icon style="font-size: 2rem;">mdi-refresh</v-icon>
                  </v-btn>
                </v-col>
                <v-btn
                  rounded
                  color="primary"
                  class="white--text px-5 mr-3"
                  @click.prevent="downloadReport"
                >
                  <v-icon
                    dense
                    class="mr-2"
                  >
                    mdi-application-export
                  </v-icon>
                  {{ $t('base.export') }}
                </v-btn>
              </v-toolbar>
              <v-container fluid>
              <v-row>
                <v-col
                  cols="3"
                  class="toolbar"
                >
                  <v-select
                    v-model="user"
                    :label="$t('base.user')"
                    :items="users"
                    item-value="id"
                    clearable
                    single-line
                  >
                    <template #item="{ item }">
                      {{ $t(item.name) }}
                    </template>
                    <template #selection="{ item }">
                      {{ $t(item.name) }}
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="2" class="toolbar">
                  <v-menu
                    v-model="fromMenu"
                    transition="slide-y-transition"
                    offset-y
                    nudge-top="450"
                    min-width="auto"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fromFormatted"
                        :label="$t('base.from')"
                        prepend-inner-icon="mdi-calendar-start"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @blur="parseDmyDate(fromFormatted)"
                      />
                    </template>
                    <datetime-picker
                      v-model="from"
                      :emit-default-time="true"
                      :default-time="'00:00:00'"
                      :max-date="today"
                      :reset-tabs="resetFromTabs"
                      @close-picker="closePicker"
                      :on-double-click="closePicker"
                    >
                      <v-col class="white text-right pb-2 px-2">
                        <v-spacer />
                        <v-btn
                          text
                          color="primary"
                          @click="closePicker"
                        >
                          {{ $t('base.ok') }}
                        </v-btn>
                      </v-col>
                    </datetime-picker>
                  </v-menu>
                </v-col>

                <v-col cols="2" class="toolbar">
                  <v-menu
                    v-model="toMenu"
                    transition="slide-y-transition"
                    nudge-top="450"
                    offset-y
                    min-width="auto"
                    :close-on-content-click="false"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="toFormatted"
                        :label="$t('base.to')"
                        prepend-inner-icon="mdi-calendar-end"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @blur="parseDmyDate(toFormatted)"
                      />
                    </template>
                    <datetime-picker
                      v-model="to"
                      :emit-default-time="true"
                      :default-time="defaultToTime"
                      :min-date="minDate"
                      :reset-tabs="resetToTabs"
                      @close-picker="closePicker"
                      :on-double-click="closePicker"
                    >
                      <v-col class="white text-right pb-2 px-2">
                        <v-spacer />
                        <v-btn
                          text
                          color="primary"
                          @click="closePicker"
                        >
                          {{ $t('base.ok') }}
                        </v-btn>
                      </v-col>
                    </datetime-picker>
                  </v-menu>
                </v-col>
                <v-col cols="3" class="toolbar">
                  <v-text-field
                    v-if="selectedModel.backend_model_id != null"
                    :label="$t('base/user.subject')"
                    clearable
                    readonly
                    :value="selectedModel.scope + ' ' + selectedModel.model"
                    @click:clear="selectModel(null)"
                  />
                </v-col>
              </v-row>
              </v-container>
            </template>
            <template #[`item.full_name`]="{ item }">
              <a @click="selectUser(item.user_id)">
                {{ item.full_name }}
              </a>
            </template>
            <template #[`item.model`]="{ item }">
              <a @click="selectModel(item)">
                {{ item.model }}
              </a>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>
<script>

import { api } from '@/global/services/api'
import { createNamespacedHelpers } from 'vuex'
import DatetimePicker from '@/global/components/DatetimePicker.vue'
import { formatSqlDateTime, parseDmyDate } from '@/global/services/helpers/dates'
import dayjs from 'dayjs'
const isToday = require('dayjs/plugin/isToday')
dayjs.extend(isToday)
const { mapGetters, mapActions } = createNamespacedHelpers('base/activity')
export default {
  name: 'UsersActivity',
  components: {
    DatetimePicker
  },
  data () {
    return {
      isLoading: false,
      users: [],
      user: null,
      selectedModel: {
        model: null,
        scope: null,
        backend_model: null,
        backend_model_id: null
      },
      resetFromTabs: false,
      resetToTabs: false,
      editItem: null,
      isDialogOpen: false,
      actionDialog: false,
      actionType: false,
      actionItemId: false,
      options: {
        page: 1,
        itemsPerPage: 20,
        sortBy: ['date_time'],
        sortDesc: [true]
      },
      fromMenu: false,
      toMenu: false,
      today: new Date().toISOString().slice(0, 10),
      from: null,
      fromFormatted: null,
      to: null,
      toFormatted: null
    }
  },

  computed: {
    ...mapGetters(['list', 'pagination']),
    wrapperStyles () {
      return {
        height: this.$vuetify.breakpoint.mdAndUp
          ? 'calc(var(--vh, 1vh) * 100 - 64px)'
          : 'calc(var(--vh, 1vh) * 100 - 56px)'
      }
    },
    header () {
      return [
        {
          text: this.$t('base.date_time'),
          value: 'date_time',
          align: 'left',
          class: 'custom-data-table-header',
          sortable: true
        },
        {
          text: this.$t('base.user'),
          value: 'full_name',
          align: 'left',
          class: 'custom-data-table-header',
          sortable: true
        },
        {
          text: this.$t('base/user.action'),
          value: 'action',
          align: 'left',
          class: 'custom-data-table-header',
          sortable: false
        },
        {
          text: this.$t('base/user.type'),
          value: 'scope',
          align: 'left',
          class: 'custom-data-table-header',
          sortable: false
        },
        {
          text: this.$t('base/user.subject'),
          value: 'model',
          align: 'center',
          class: 'custom-data-table-header',
          sortable: false
        }
      ]
    },
    defaultToTime () {
      return '23:59:59'
    },

    minDate () {
      return dayjs(this.from).format('YYYY-MM-DD')
    },

    maxToDate () {
      if (this.from) {
        const maxDate = dayjs(this.from).add(31, 'day')
        return maxDate.isAfter(this.today) ? this.today : maxDate.toISOString()
      }

      return this.today
    },

    maxToTime () {
      if (dayjs(this.to).isToday()) return dayjs().format('HH:mm')

      return this.defaultToTime
    },
    loader () {
      return this.isLoading
    }
  },

  watch: {
    user: {
      handler () {
        this.getItems()
      }
    },
    selectedModel: {
      handler () {
        this.getItems()
      }
    },
    from (from) {
      this.fromFormatted = formatSqlDateTime(from, 'DD.MM.YYYY HH:mm')
    },

    to (to) {
      this.toFormatted = formatSqlDateTime(to, 'DD.MM.YYYY HH:mm')
    },
    fromMenu (value) {
      this.resetFromTabs = !this.resetFromTabs
    },
    toMenu (value) {
      this.resetToTabs = !this.resetToTabs
    }
  },

  created () {
    this.from = dayjs().subtract(1, 'month').startOf('day').toISOString()
    this.to = dayjs().toISOString()
    this.getUsers()
    this.getItems()
  },

  methods: {
    ...mapActions(['fetch']),
    async getUsers () {
      const response = await api().base.get('users/autocomplete', { query: '' })
      this.users = response.data
    },
    async getItems () {
      this.isLoading = true
      const params = {
        user: this.user,
        pagination: {
          perPage: this.options.itemsPerPage,
          currentPage: this.options.page
        },
        sort: {
          by: this.options.sortBy[0] ?? null,
          desc: this.options.sortDesc[0] ?? null
        },
        selectedModel: this.selectedModel.backend_model_id ? {
          model: this.selectedModel.backend_model,
          id: this.selectedModel.backend_model_id
        } : null,
        from: this.from,
        to: this.to
      }
      await this.fetch(params)
      if (this.pagination.currentPage > this.pagination.totalPages) {
        this.options.page = 1
      }
      this.isLoading = false
    },

    selectUser (id) {
      this.user = id
    },

    selectModel (item) {
      if (item === null) {
        this.selectedModel = {
          model: null,
          scope: null,
          backend_model: null,
          backend_model_id: null
        }
      }
      else {
        this.selectedModel = {
          model: item.model,
          scope: item.scope,
          backend_model: item.backend_model,
          backend_model_id: item.backend_model_id
        }
      }
    },
    parseDmyDate (date) {
      return parseDmyDate(date)
    },
    closePicker () {
      if (this.fromMenu) {
        this.fromMenu = false
      }
      if (this.toMenu) {
        this.toMenu = false
      }

      this.getItems()
    },
    downloadReport () {
      const url = '/api/base/user-activity/export'

      return fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          user: this.user,
          selectedModel: this.selectedModel.backend_model_id ? {
            model: this.selectedModel.backend_model,
            id: this.selectedModel.backend_model_id
          } : null,
          from: this.from,
          to: this.to
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          if (!res.ok) {
            const responseStatusText = res.statusText
            const errorMessage = `${responseStatusText}`
            throw new Error(errorMessage)
          }

          return res.arrayBuffer()
        })
        .then(arrayBuffer => {
          // BE endpoint sends a readable stream of bytes
          const byteArray = new Uint8Array(arrayBuffer)
          const link = window.document.createElement('a')
          link.href = window.URL.createObjectURL(
            new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          )
          link.download = `${this.$t('base/users_activity.export_title')}.xlsx`
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
        .catch(error => {
          throw new Error('Error occurred:' + error)
        })
        .finally(() => {
        })
    }
  }
}
</script>
<style scoped lang="scss">
.toolbar-title {
  padding-left: 1rem;
  color: #869DB6;
}
.toolbar {
  padding: 0 0 0 1rem;
}
.custom-data-table-header {
  color: #869DB6 !important;
}
</style>
