<template>
  <index-view
    view-name="reminders"
    :module="module"
    :model="model"
    :list="list"
    :pagination="pagination"
    :sort="sort"
    :view-config="viewConfig"
    :fetch="fetch"
  />
</template>

<script>
import indexView from '@/global/components/Index'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapActions } = createNamespacedHelpers('satellite-tracking/reminders')
export default {
  name: 'RemindersView',
  components: {
    indexView
  },
  props: {
    module: {
      type: String,
      default: 'satellite-tracking'
    },
    model: {
      type: String,
      default: 'reminders'
    }
  },
  computed: {
    ...mapGetters(['list', 'pagination', 'sort', 'viewConfig'])
  },
  methods: {
    ...mapActions(['fetch'])
  }
}
</script>
