<template>
  <div class="v-main__wrap">
    <div class="container container--fluid" style="position: relative;">
      <div class="v-card v-sheet theme--light">
        <div class="v-data-table elevation-0 v-data-table--has-top v-data-table--has-bottom theme--light table-stripped">
          <header class="toolbar-container v-sheet theme--light v-toolbar v-toolbar--flat" style="height: 56px;">
            <div class="v-toolbar__content" style="height: 56px;">
              <div class="header-row">
                <div class="v-toolbar__title toolbar-title">{{ $t('base.tracker_search') }}</div>
                <div class="float-right">
                  <input
                    class="form-control form-control-sm"
                    type="text"
                    placeholder="IMEI..."
                    aria-label="IMEI..."
                    style="height: 38px"
                    v-model="imei"
                    @input="getTracker(imei)"
                  />
                </div>
              </div>
            </div>
          </header>
          <hr role="separator" aria-orientation="horizontal" class="v-divider theme--light">
          <div class="v-data-table__wrapper">
            <table>
              <thead class="v-data-table-header">
              <tr v-if="tracker">
                <th role="columnheader" scope="col" class="text-left custom-data-table-header"><span>IMEI</span></th>
                <th role="columnheader" scope="col" class="text-left custom-data-table-header"><span>{{ $t('base/tracker.serial') }}</span></th>
                <th role="columnheader" scope="col" class="text-left custom-data-table-header"><span>{{ $t('base.company') }}</span></th>
              </tr>
              </thead>
              <tbody>
              <tr class="" v-if="tracker">
                <td class="text-left"><span>{{ tracker.imei}}</span></td>
                <td class="text-left"><span>{{ tracker.serial}}</span></td>
                <td class="text-left"><span>{{ tracker.company.name}}</span></td>
              </tr>
              <tr class="" v-else>
                <td class="text-center" colspan="3">
                  <h2 class="text-center my-4"><i class="text-danger">{{ $t('base/tracker_search.no_tracker') }}</i></h2>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '@/global/services/api'

export default {
  data () {
    return {
      tracker: null,
      imei: null
    }
  },
  methods: {
    async getTracker (imei) {
      try {
        const { data } = await api().base.get('tracker/imei/' + imei)
        this.tracker = data
      }
      catch (e) {
        this.tracker = null
      }
    }
  }
}
</script>
