<template>
  <div class="v-main__wrap">
    <div class="container container--fluid" style="position: relative;">
      <div class="v-card v-sheet theme--light">
        <div class="v-data-table elevation-0 v-data-table--has-top v-data-table--has-bottom theme--light table-stripped">
          <header class="toolbar-container v-sheet theme--light v-toolbar v-toolbar--flat" style="height: 56px;">
            <div class="v-toolbar__content" style="height: 56px;">
              <div class="header-row">
                <div class="v-toolbar__title toolbar-title">{{ $t('base.sim_search') }}</div>
                <div class="float-right">
                  <input
                    class="form-control form-control-sm"
                    type="text"
                    placeholder="ICCID..."
                    aria-label="ICCID..."
                    style="height: 38px"
                    v-model="iccid"
                    @input="getSim(iccid)"
                  />
                </div>
              </div>
            </div>
          </header>
          <hr role="separator" aria-orientation="horizontal" class="v-divider theme--light">
          <div class="v-data-table__wrapper">
            <table>
              <thead class="v-data-table-header">
              <tr v-if="sim">
                <th role="columnheader" scope="col" class="text-left custom-data-table-header"><span>ICCID</span></th>
                <th role="columnheader" scope="col" class="text-left custom-data-table-header"><span>{{ $t('base/sim.voice_number') }}</span></th>
                <th role="columnheader" scope="col" class="text-left custom-data-table-header"><span>{{ $t('base.company') }}</span></th>
              </tr>
              </thead>
              <tbody>
              <tr class="" v-if="sim">
                <td class="text-left"><span>{{ sim.iccid}}</span></td>
                <td class="text-left"><span>{{ sim.voice_number}}</span></td>
                <td class="text-left"><span>{{ sim.company.name}}</span></td>
              </tr>
              <tr class="" v-else>
                <td class="text-center" colspan="2">
                  <h2 class="text-center my-4"><i class="text-danger">{{ $t('base/sim_search.no_sim') }}</i></h2>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '@/global/services/api'

export default {
  data () {
    return {
      sim: null,
      iccid: null
    }
  },
  methods: {
    async getSim (iccid) {
      try {
        const { data } = await api().base.get('sim/iccid/' + iccid)
        this.sim = data
      }
      catch (e) {
        this.sim = null
      }
    }
  }
}
</script>
