import { render, staticRenderFns } from "./SimTariffsView.vue?vue&type=template&id=2b36e1fc&"
import script from "./SimTariffsView.vue?vue&type=script&lang=js&"
export * from "./SimTariffsView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports