<template>
  <table-index
    :config="tableConfig"
    :create-config="createDetailsConfig"
    @tab-content-instance="instance => tabContentInstance = instance"
    @main-table-instance="instance => tableInstance = instance"
  >
    <template #dynamic-content>
      <signature-dialog
        v-if="signatureDialogData.show"
        :show-dialog="signatureDialogData.show"
        :config="signatureDialogData.config"
        :form-data="signatureDialogData.formData"
        :signature-field-key="signatureDialogData.fieldKey"
        :signature-id="signatureDialogData.signatureId"
        @close="closeSignatureDialog"
      />

      <change-status-dialog
        v-if="changeStatusDialogConfig.show"
        :show-dialog="changeStatusDialogConfig.show"
        :title="changeStatusDialogConfig.title"
        :statuses="changeStatusDialogConfig.statuses"
        :current-status="changeStatusDialogConfig.current_status"
        :api-config="changeStatusDialogConfig.apiConfig"
        :document-id="changeStatusDialogConfig.document_id"
        @close="closeChangeStatusDialog"
      />

      <information-dialog
        v-if="informationDialogData.show"
        :open-dialog="informationDialogData.show"
        :dialog-message="informationDialogData.message"
        :negative-button-text="informationDialogData.cancelText"
        :positive-button-text="informationDialogData.confirmText"
        :title="informationDialogData.title"
        :confirm-callback-function="informationDialogData.callback"
        @close="onCloseInformationDialog"
      />
    </template>
  </table-index>
</template>

<script>
import TableIndex from '@/global/components/table/TableIndex.vue'
import SignatureDialog from '../../components/SignatureDialog.vue'
import { currentDate, currentTime } from '@/global/services/helpers/dates'
import { api } from '@/global/services/api'
import { isEmpty } from 'lodash'
import ChangeStatusDialog from '../../components/ChangeStatus.vue'
import InformationDialog from '@/global/components/view-layouts/tabs-layout/components/dialogs/InformationDialog.vue'

export default {
  name: 'PrlCargoView',

  components: {
    InformationDialog,
    ChangeStatusDialog,
    SignatureDialog,
    TableIndex
  },

  data () {
    return {
      signatureDialogData: {
        show: false,
        config: {},
        formData: {},
        fieldKey: '',
        signatureId: null
      },
      signatureDialogConfig: {
        title: this.$t('fleet/documents/prl_cargo.sign_document'),
        confirmDialogTitle: this.$t('fleet/documents/prl_cargo.sign_document'),
        apiConfig: {
          get: {
            module: 'fleet',
            route: 'vehicle/get-basic-info',
            method: 'get'
          }
        },
        pinConfirmationApiConfig: {
          post: {
            module: 'fleet',
            route: 'travel-documents/check-pin',
            method: 'post'
          }
        }
      },
      defaultSignatureData: {
        id: null,
        image: null,
        pin: null
      },
      tabContentInstance: null,
      tableInstance: null,
      changeStatusDialogConfig: {
        show: false,
        apiConfig: {
          module: 'fleet',
          route: 'travel-documents/change-status',
          method: 'post'
        },
        getStatusesApiConfig: {
          module: 'fleet',
          route: 'travel-documents/statuses',
          method: 'get'
        },
        title: this.$t('fleet/documents/prl_cargo.select_new_status'),
        statuses: [],
        current_status: null,
        document_id: null
      },
      informationDialogData: {
        show: false,
        message: '',
        cancelText: '',
        confirmText: '',
        title: '',
        callback: null
      }
    }
  },

  computed: {
    tableConfig () {
      return {
        tableConfig: {
          apiConfig: {
            get: {
              module: 'fleet',
              route: 'travel-documents/prl-cargo',
              method: 'get'
            }
          },
          rowClick: (event) => this.showPrlCargoDetails(event),
          search_and_buttons_config: {
            newClick: (event) => this.showCreateNewPrlCargo(event)
          },
          title_and_back_config: {
            title: this.$t('fleet.prl_cargo')
          },
          tableConfig: {
            styles: {
              useFixedHeaderWidth: true,
              headersHeight: '76px',
              dataRowHeight: '64px'
            },
            cellsConfig: {
              driver: {
                type: 'object',
                displayValue: 'fullName'
              },
              status: {
                type: 'status',
                displayValue: 'description',
                colorObjectKey: 'color',
                textColorKey: 'text',
                backgroundColorKey: 'background',
                style: {
                  textAlign: '-webkit-center'
                },
                statusStyle: {
                  textAlign: 'center',
                  borderRadius: '8px',
                  height: '24px',
                  paddingRight: '12px',
                  paddingLeft: '12px',
                  width: 'max-content'
                }
              }
            },
            headers: [
              {
                label: this.$t('fleet/documents/prl_cargo.sr_no'),
                key: 'id',
                style: {
                  width: '100px'
                },
                always_visible: true
              },
              {
                label: this.$t('fleet/documents/prl_cargo.prl_no'),
                key: 'foreign_code',
                style: {
                  width: '130px'
                },
                always_visible: true
              },
              {
                label: this.$t('fleet/documents/prl_cargo.creation_date'),
                key: 'date_time',
                style: {
                  width: '155px'
                },
                always_visible: true
              },
              {
                label: this.$t('fleet/vehicle.driver'),
                key: 'driver_name',
                style: {
                  width: '172px'
                },
                always_visible: true
              },
              {
                label: this.$t('fleet/vehicle.label'),
                key: 'vehicle_brand',
                style: {
                  width: '172px'
                },
                always_visible: true
              },
              {
                label: this.$t('fleet/vehicle.registration'),
                key: 'vehicle_registration',
                style: {
                  width: '172px'
                },
                always_visible: true
              },
              {
                label: this.$t('fleet/vehicle_attribute.garage_number'),
                key: 'vehicle_garage_number',
                style: {
                  width: '172px'
                },
                always_visible: true
              },
              {
                label: this.$t('fleet.organizational_unit'),
                key: 'organizational_unit',
                style: {
                  width: '160px'
                },
                always_visible: true
              },
              {
                label: this.$t('fleet.department'),
                key: 'department',
                style: {
                  width: '130px'
                },
                always_visible: true
              },
              {
                label: this.$t('fleet/vehicle.status'),
                key: 'status',
                style: {
                  width: '200px',
                  textAlign: 'center'
                },
                always_visible: true
              }
            ],
            paginationConfig: {
              perPageOptions: [20, 50, 100],
              perPage: 20,
              currentPage: 1
            },
            actionsConfig: [
              {
                label: this.$t('base.edit'),
                icon: 'mdi-pencil-box',
                click: (event) => this.showPrlCargoDetails(event)
              },
              {
                type: 'base',
                label: this.$t('fleet/documents/prl_cargo.change_status'),
                icon: 'mdi-swap-horizontal',
                click: (event) => this.onChangeStatus(event)
              },
              {
                label: this.$t('base.delete'),
                icon: 'mdi-delete',
                click: (event) => this.onDocumentDelete(event)
              }
            ],
            filtersConfig: {
              fields: [
                {
                  key: 'id',
                  label: this.$t('fleet/documents/prl_cargo.sr_no')
                },
                {
                  key: 'foreign_code',
                  label: this.$t('fleet/documents/prl_cargo.prl_no')
                },
                {
                  key: 'date_time',
                  label: this.$t('fleet/documents/prl_cargo.creation_date')
                },
                {
                  key: 'driver_name',
                  label: this.$t('fleet/vehicle.driver')
                },
                {
                  key: 'vehicle_brand',
                  label: this.$t('fleet/vehicle.label')
                },
                {
                  key: 'vehicle_registration',
                  label: this.$t('fleet/vehicle.registration')
                },
                {
                  key: 'vehicle_garage_number',
                  label: this.$t('fleet/vehicle_attribute.garage_number')
                },
                {
                  key: 'organizational_unit',
                  label: this.$t('fleet.organizational_unit')
                },
                {
                  key: 'department',
                  label: this.$t('fleet.department')
                },
                {
                  key: 'status',
                  label: this.$t('fleet/vehicle.status')
                }
              ]
            }
          }
        },
        breadcrumbs: {
          items: [
            {
              text: this.$t('fleet.travel_documents')
            },
            {
              text: this.$t('fleet.prl_cargo'),
              bolded: true
            }
          ]
        }
      }
    },

    createDetailsConfig () {
      return {
        tabs: [
          {
            title: this.$t('fleet/vehicle.basic_info'),
            apiConfig: {
              post: {
                module: 'fleet',
                route: 'travel-documents/prl-cargo',
                method: 'post'
              }
            },
            fileKeys: [
              'signature_image'
            ],
            hiddenFormKeys: [
              'vehicle_id',
              'trailer_id',
              'crew_members'
            ],
            additionalSetup: async (event) => await this.callBasicInfoAdditionalSetup(event),
            additionalDataManipulation: (data) => this.handleAdditionalDataManipulation(data),
            afterSave: (event, data) => this.redirectToEdit(event, data),
            tabContent: [
              // PRL data card
              {
                title: this.$t('fleet/vehicle.basic_info'),
                subTitle: this.$t('fleet/documents/prl_cargo.data_of_prl'),
                type: 'columns',
                showButtons: true,
                fullWidth: true,
                useInitialAutocompletes: false,
                rows: [
                  {
                    type: 'fields',
                    columnsCount: 4,
                    fields: [
                      {
                        key: 'foreign_code',
                        label: this.$t('fleet/documents/prl_cargo.prl_no'),
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'date_time',
                        label: this.$t('fleet/documents/prl_cargo.creation_date'),
                        type: 'datetime-picker',
                        appendIcon: 'mdi-calendar-month',
                        format: 'DD.MM.YYYY. HH:mm',
                        initialValue: currentDate('DD.MM.YYYY.') + ' ' + currentTime('HH:mm'),
                        placeholder: 'DD.MM.YYYY. HH:mm',
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'status_name',
                        label: this.$t('fleet/vehicle.status'),
                        initialValue: 'U obradi',
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'transport_type',
                        label: this.$t('fleet/documents/prl_cargo.transport_type'),
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'company_name',
                        label: this.$t('fleet/documents/prl_cargo.company_name_and_address'),
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'organizational_unit',
                        label: this.$t('fleet.organizational_unit'),
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'department',
                        label: this.$t('fleet.department'),
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'cost_location',
                        label: this.$t('fleet/documents/prl_cargo.cost_location'),
                        placeholder: this.$t('fleet/documents/prl_cargo.cost_location_placeholder'),
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'relation',
                        label: this.$t('fleet/documents/prl_cargo.relation_or_direction'),
                        placeholder: this.$t('fleet/documents/prl_cargo.relation_or_direction_placeholder'),
                        creatable: true,
                        editable: true,
                        visible: true
                      }
                    ]
                  }
                ]
              },
              // Crew members card
              {
                subTitle: this.$t('fleet/documents/prl_cargo.crew_members'),
                type: 'columns',
                fullWidth: true,
                rows: [
                  {
                    type: 'fields',
                    columnsCount: 4,
                    fields: [
                      {
                        key: 'driver',
                        label: this.$t('fleet/vehicle.driver'),
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'crew_member_1',
                        label: this.$t('fleet/documents/prl_cargo.other_crew_members').replace(':number', 1),
                        placeholder: this.$t('fleet/documents/prl_cargo.other_crew_members_placeholder').replace(':number', 1),
                        type: 'autocomplete',
                        autocomplete_options: {
                          module: 'people',
                          route: 'people/autocomplete',
                          fetchInitial: false,
                          returnValue: 'object'
                        },
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'crew_member_2',
                        label: this.$t('fleet/documents/prl_cargo.other_crew_members').replace(':number', 2),
                        placeholder: this.$t('fleet/documents/prl_cargo.other_crew_members_placeholder').replace(':number', 2),
                        type: 'autocomplete',
                        autocomplete_options: {
                          module: 'people',
                          route: 'people/autocomplete',
                          fetchInitial: false,
                          returnValue: 'object'
                        },
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'crew_member_3',
                        label: this.$t('fleet/documents/prl_cargo.other_crew_members').replace(':number', 3),
                        placeholder: this.$t('fleet/documents/prl_cargo.other_crew_members_placeholder').replace(':number', 1),
                        type: 'autocomplete',
                        autocomplete_options: {
                          module: 'people',
                          route: 'people/autocomplete',
                          fetchInitial: false,
                          returnValue: 'object'
                        },
                        creatable: true,
                        editable: true,
                        visible: true
                      }
                    ]
                  }
                ]
              },
              // Vehicle and trailer data card
              {
                subTitle: this.$t('fleet/documents/prl_cargo.vehicle_and_trailer_data'),
                type: 'columns',
                fullWidth: true,
                rows: [
                  {
                    type: 'fields',
                    columnsCount: 3,
                    fields: [
                      {
                        key: 'vehicle_brand',
                        label: this.$t('fleet/documents/prl_cargo.vehicle_brand'),
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'vehicle_registration',
                        label: this.$t('fleet/vehicle.registration'),
                        placeholder: this.$t('fleet/documents/prl_cargo.registration_number_placeholder'),
                        type: 'autocomplete',
                        onSelect: (event) => this.onVehicleRegistrationSelect(event),
                        autocomplete_options: {
                          module: 'fleet',
                          route: 'travel-documents/get-vehicle',
                          custom_display_value: () => {
                            return 'vehicle_registration'
                          },
                          additionalQueryParams: {
                            by: 'registration',
                            vehicle_type: 'vehicle',
                            document_type: 'PRL_CARGO'
                          },
                          returnValue: 'object',
                          form_value: 'vehicle_registration'
                        },
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'vehicle_garage_number',
                        label: this.$t('fleet/vehicle_attribute.garage_number'),
                        placeholder: this.$t('fleet/documents/prl_cargo.garage_number_placeholder'),
                        type: 'autocomplete',
                        onSelect: (event) => this.onVehicleGarageNumberSelect(event),
                        autocomplete_options: {
                          module: 'fleet',
                          route: 'travel-documents/get-vehicle',
                          custom_display_value: () => {
                            return 'vehicle_garage_number'
                          },
                          additionalQueryParams: {
                            by: 'garage_number',
                            vehicle_type: 'vehicle',
                            document_type: 'PRL_CARGO'
                          },
                          returnValue: 'object',
                          form_value: 'vehicle_garage_number'
                        },
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'trailer_brand',
                        label: this.$t('fleet/documents/prl_cargo.trailer_brand'),
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'trailer_registration',
                        label: this.$t('fleet/vehicle.registration'),
                        placeholder: this.$t('fleet/documents/prl_cargo.registration_number_placeholder'),
                        type: 'autocomplete',
                        onSelect: (event) => this.onTrailerRegistrationSelect(event),
                        autocomplete_options: {
                          module: 'fleet',
                          route: 'travel-documents/get-vehicle',
                          custom_display_value: () => {
                            return 'trailer_registration'
                          },
                          additionalQueryParams: {
                            by: 'registration',
                            vehicle_type: 'trailer',
                            document_type: 'PRL_CARGO'
                          },
                          returnValue: 'object',
                          form_value: 'trailer_registration'
                        },
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'trailer_garage_number',
                        label: this.$t('fleet/vehicle_attribute.garage_number'),
                        placeholder: this.$t('fleet/documents/prl_cargo.garage_number_placeholder'),
                        type: 'autocomplete',
                        onSelect: (event) => this.onTrailerGarageNumberSelect(event),
                        autocomplete_options: {
                          module: 'fleet',
                          route: 'travel-documents/get-vehicle',
                          custom_display_value: () => {
                            return 'trailer_garage_number'
                          },
                          additionalQueryParams: {
                            by: 'garage_number',
                            vehicle_type: 'trailer',
                            document_type: 'PRL_CARGO'
                          },
                          returnValue: 'object',
                          form_value: 'trailer_garage_number'
                        },
                        creatable: true,
                        editable: true,
                        visible: true
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            title: this.$t('fleet/documents/prl_cargo.vehicle_movement'),
            disabled: true
          },
          {
            title: this.$t('fleet/documents/prl_cargo.garage'),
            disabled: true
          },
          {
            title: this.$t('fleet/documents/prl_cargo.fuel_and_lubricant'),
            disabled: true
          },
          {
            title: this.$t('fleet/documents/prl_cargo.mileage_of_tires'),
            disabled: true
          },
          {
            title: this.$t('fleet/documents/prl_cargo.planned_completion'),
            disabled: true
          }
        ],
        breadcrumbs: {
          items: [
            {
              text: this.$t('fleet.travel_documents')
            },
            {
              text: this.$t('fleet.prl_cargo')
            },
            {
              text: this.$t('fleet/documents/prl_cargo.add_new_prl'),
              bolded: true
            }
          ]
        }
      }
    }
  },

  methods: {
    showPrlCargoDetails (event) {
      const { data } = event || {}
      const { id, foreign_code: foreignCode } = data || {}

      if (id) {
        this.$router.push({
          name: 'PrlCargoDetailsView',
          params: {
            id: id,
            breadCrumbValue: foreignCode ?? this.$t('fleet/documents/prl_cargo.document_edit')
          }
        })
      }
    },

    redirectToEdit (event, data) {
      const { id, foreign_code: foreignCode } = data || {}

      if (id) {
        this.$router.push({
          name: 'PrlCargoDetailsView',
          params: {
            id: id,
            breadCrumbValue: foreignCode ?? this.$t('fleet/documents/prl_cargo.document_edit')
          }
        })
      }
    },

    async onChangeStatus (event) {
      const { id } = event?.data?.status || {}
      const { id: documentId } = event?.data || {}

      if (this.changeStatusDialogConfig.statuses.length) {
        this.$set(this.changeStatusDialogConfig, 'current_status', id)
        this.$set(this.changeStatusDialogConfig, 'document_id', documentId)
        this.$set(this.changeStatusDialogConfig, 'show', true)
      }
      else {
        const { method, module, route } = this.changeStatusDialogConfig?.getStatusesApiConfig || {}

        if (method && module && route) {
          const { data } = await api()[module][method](route)
          if (data && data.length) {
            this.$set(this.changeStatusDialogConfig, 'statuses', data)
            this.$set(this.changeStatusDialogConfig, 'current_status', id)
            this.$set(this.changeStatusDialogConfig, 'document_id', documentId)
            this.$set(this.changeStatusDialogConfig, 'show', true)
          }
        }
      }
    },

    async closeChangeStatusDialog (shouldRefreshData = false) {
      if (shouldRefreshData && this.tableInstance) {
        const { fetchData } = this.tableInstance || {}
        if (typeof fetchData === 'function') {
          await fetchData()
        }
      }

      this.$set(this.changeStatusDialogConfig, 'show', false)
    },

    showCreateNewPrlCargo (event) {
      if (event && event.showTableNewPage && typeof event.showTableNewPage === 'function') {
        event.showTableNewPage()
      }
    },

    closeSignatureDialog (data = null) {
      if (!isEmpty(data)) {
        const { formData, image, save } = data || {}
        if (formData) {
          formData.signature_image = image
          formData.signature_to_profile = save
        }
      }
      this.$set(this.signatureDialogData, 'show', false)
    },

    async onVehicleRegistrationSelect (event) {
      const { formData, instance, selectedItem } = event || {}

      if (formData && selectedItem) {
        formData.transport_type = selectedItem.transport_type || ''
        formData.organizational_unit = selectedItem.organizational_unit ?? ''
        formData.department = selectedItem.department || ''
        formData.vehicle_id = selectedItem.id || null
        formData.vehicle_brand = selectedItem.vehicle_brand || ''

        // We need to call an autocomplete api to fill garage number items because we need to display garage number automatically given from vehicle registration selected value
        if (selectedItem.vehicle_garage_number) {
          const { data: garageNumberData, has_more: hasMore = false } = await api().fleet.get('travel-documents/get-vehicle', {
            query: selectedItem.vehicle_garage_number.toString(),
            by: 'garage_number',
            vehicle_type: 'vehicle',
            document_type: 'PRL_CARGO'
          })

          if (instance && instance.initialAutocompletes) {
            this.$set(instance.initialAutocompletes, 'vehicle_garage_number', {
              data: garageNumberData,
              hasMore: hasMore
            })

            this.$set(formData, 'vehicle_garage_number', selectedItem.vehicle_garage_number || '')
          }
        }
      }
    },

    async onVehicleGarageNumberSelect (event) {
      const { formData, instance, selectedItem } = event || {}

      if (formData && selectedItem) {
        formData.transport_type = selectedItem.transport_type || ''
        formData.organizational_unit = selectedItem.organizational_unit ?? ''
        formData.department = selectedItem.department || ''
        formData.vehicle_id = selectedItem.id || null
        formData.vehicle_brand = selectedItem.vehicle_brand || ''

        // We need to call an autocomplete api to fill garage number items because we need to display garage number automatically given from vehicle registration selected value
        if (selectedItem.vehicle_registration) {
          const { data: garageNumberData, has_more: hasMore = false } = await api().fleet.get('travel-documents/get-vehicle', {
            query: selectedItem.vehicle_registration.toString(),
            by: 'registration',
            vehicle_type: 'vehicle',
            document_type: 'PRL_CARGO'
          })

          if (instance && instance.initialAutocompletes) {
            this.$set(instance.initialAutocompletes, 'vehicle_registration', {
              data: garageNumberData,
              hasMore: hasMore
            })

            this.$set(formData, 'vehicle_registration', selectedItem.vehicle_registration || '')
          }
        }
      }
    },

    async onTrailerRegistrationSelect (event) {
      const { formData, selectedItem, instance } = event || {}

      if (formData && selectedItem) {
        formData.trailer_brand = selectedItem.trailer_brand || ''
        formData.trailer_id = selectedItem.id || null

        if (selectedItem.trailer_garage_number) {
          const { data: garageNumberData, has_more: hasMore = false } = await api().fleet.get('travel-documents/get-vehicle', {
            query: selectedItem.trailer_garage_number.toString(),
            by: 'garage_number',
            vehicle_type: 'trailer',
            document_type: 'PRL_CARGO'
          })

          if (instance && instance.initialAutocompletes) {
            this.$set(instance.initialAutocompletes, 'trailer_garage_number', {
              data: garageNumberData,
              hasMore: hasMore
            })

            this.$set(formData, 'trailer_garage_number', selectedItem.trailer_garage_number || '')
          }
        }
      }
    },

    async onTrailerGarageNumberSelect (event) {
      const { formData, selectedItem, instance } = event || {}

      if (formData && selectedItem) {
        formData.trailer_brand = selectedItem.trailer_brand || ''
        formData.trailer_id = selectedItem.id || null

        if (selectedItem.trailer_registration) {
          const { data: registrationData, has_more: hasMore = false } = await api().fleet.get('travel-documents/get-vehicle', {
            query: selectedItem.trailer_registration.toString(),
            by: 'registration',
            vehicle_type: 'trailer',
            document_type: 'PRL_CARGO'
          })

          if (instance && instance.initialAutocompletes) {
            this.$set(instance.initialAutocompletes, 'trailer_registration', {
              data: registrationData,
              hasMore: hasMore
            })

            this.$set(formData, 'trailer_registration', selectedItem.trailer_registration || '')
          }
        }
      }
    },

    async callBasicInfoAdditionalSetup (event) {
      const { initialAutocompletes, formDataForSend } = event || {}

      if (initialAutocompletes === undefined) {
        return
      }

      const { data: peopleData, has_more: hasMore = false } = await api().people.get('people/autocomplete', {})
      if (peopleData && peopleData.length) {
        this.$set(initialAutocompletes, 'crew_member_1', {
          data: peopleData,
          hasMore: hasMore
        })

        this.$set(initialAutocompletes, 'crew_member_2', {
          data: peopleData,
          hasMore: hasMore
        })

        this.$set(initialAutocompletes, 'crew_member_3', {
          data: peopleData,
          hasMore: hasMore
        })
      }

      const { data: driverCompanyData } = await api().fleet.get('travel-documents/get-driver-company-from-user', {})

      if (driverCompanyData) {
        const { person, company } = driverCompanyData || {}
        this.$set(formDataForSend, 'driver', person?.name || '')
        this.$set(formDataForSend, 'company_name', company?.name || '')
      }
    },

    handleAdditionalDataManipulation (data) {
      const {
        crew_member_1: crewMember1,
        crew_member_2: crewMember2,
        crew_member_3: crewMember3
      } = data || {}

      const crewMemberIds = [crewMember1, crewMember2, crewMember3].filter(Boolean)

      // Add the array only if at least one member exists
      if (crewMemberIds.length) {
        data.crew_members = crewMemberIds
        this.$delete(data, 'crew_member_1')
        this.$delete(data, 'crew_member_2')
        this.$delete(data, 'crew_member_3')
      }
    },

    async onDocumentDelete (event) {
      try {
        this.$set(this.informationDialogData, 'message', this.$t('fleet/documents/prl_cargo.delete_document_message'))
        this.$set(this.informationDialogData, 'cancelText', this.$t('base.cancel'))
        this.$set(this.informationDialogData, 'confirmText', this.$t('base.confirm'))
        this.$set(this.informationDialogData, 'title', this.$t('fleet/vehicle/documents_tab.delete_dialog_title'))
        this.$set(this.informationDialogData, 'callback', async () => await this.deleteDocumentCallback(event))
        this.$set(this.informationDialogData, 'show', true)
      }
      catch (exception) {
        console.log(exception)
      }
    },

    async deleteDocumentCallback (event) {
      try {
        const { id } = event?.data || {}
        const { fetchData } = this.tableInstance || {}
        if (id) {
          await api().fleet.delete(`travel-documents/prl-cargo/${id}`)

          this.$set(this.informationDialogData, 'show', false)
          if (fetchData && typeof fetchData === 'function') {
            fetchData()
          }
        }
      }
      catch (exception) {
        this.$set(this.informationDialogData, 'show', false)
        console.log(exception)
      }
    },

    onCloseInformationDialog () {
      this.$set(this.informationDialogData, 'show', false)
    }
  }
}
</script>
