<template>
  <index-view
    view-name="tracker_firmware_versions"
    :module="module"
    :model="model"
    :list="list"
    :pagination="pagination"
    :sort="sort"
    :view-config="viewConfig"
    :fetch="fetch"
  />
</template>

<script>
import indexView from '@/global/components/Index'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('base/tracker-firmware-versions')
export default {
  name: 'TrackerFirmwareVersionsView',
  components: {
    'index-view': indexView
  },
  props: {
    module: {
      type: String,
      default: 'base'
    },
    model: {
      type: String,
      default: 'tracker-firmware-versions'
    }
  },
  computed: {
    ...mapGetters(['list', 'pagination', 'sort', 'viewConfig'])
  },
  methods: {
    ...mapActions(['fetch'])
  }
}
</script>
