<template>
  <div>
    <v-container fluid>
      <v-btn
        block
        color="accent"
        :loading="destroying"
        :disabled="destroying"
        @click="destroyAllOtherSessions"
      >
        {{ $t('base/session.destroy_all_other') }}
      </v-btn>
    </v-container>
    <index-view
      ref="table"
      :module="module"
      :viewName="'SessionsView'"
      :model="model"
      :list="list"
      :pagination="pagination"
      :sort="sort"
      :view-config="viewConfig"
      :fetch="fetch"
    />
  </div>
</template>

<script>
import indexView from '@/global/components/Index'
import { api } from '@/global/services/api'
import { createNamespacedHelpers } from 'vuex'

const {
  mapGetters,
  mapActions
} = createNamespacedHelpers('base/sessions')

export default {
  name: 'SessionsView',
  components: {
    'index-view': indexView
  },
  props: {
    module: {
      type: String,
      default: 'base'
    },
    model: {
      type: String,
      default: 'session'
    }
  },
  data () {
    return {
      destroying: false
    }
  },
  computed: {
    ...mapGetters(['list', 'pagination', 'sort', 'viewConfig'])
  },
  methods: {
    ...mapActions(['fetch']),
    async destroyAllOtherSessions () {
      this.destroying = true
      await api()[this.module].delete('/session/all')
      this.destroying = false
      await this.$refs.table.getItems()
    }
  }
}
</script>
